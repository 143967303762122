
import { defineComponent, ref, onMounted, computed } from 'vue'
import Datatable from '@/components/kt-datatable/KTDatatable.vue'
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb'
import { useStore } from 'vuex'
import { Actions, Mutations, MutationsActions } from '@/store/enums/StoreEnums'
import { CompaniesPayload } from '@/store/modules/CompanyModule'
import { ArrowDown } from '@element-plus/icons-vue'
import router from '@/router/clean'
import dayjs from 'dayjs'
import { Metrics, MetricsPayload } from '@/store/modules/StatisticModule'
import Swal from 'sweetalert2/dist/sweetalert2.min.js'

export default defineComponent({
  name: 'companies-listing',
  components: {
    Datatable,
    ArrowDown
  },
  setup () {
    const checkedCustomers = ref([])
    const tableHeader = ref([
      // {
      //   key: 'checkbox',
      //   sortable: false
      // },
      {
        name: 'Company',
        key: 'company',
        sortable: false
      },
      {
        name: 'Customer Name',
        key: 'name',
        sortable: false
      },
      {
        name: 'Email',
        key: 'email',
        sortable: false
      },
      {
        name: 'Created Date',
        key: 'date',
        sortable: false
      },
      {
        name: 'Activation Status',
        key: 'activation-status',
        sortable: false
      },
      {
        name: 'Actions',
        key: 'actions',
        sortable: false
      }
    ])
    const store = useStore()

    const itemsPerPage = ref<number>(10)
    const selectedPage = ref<number>(0)

    const isGetCompaniesInProgress = computed(() => store.getters.isGetCompaniesInProgress)
    const tableData = ref(computed(() => store.getters.getCompanies))
    const companiesCounter = computed(() => store.getters.getApiCompaniesCounter)
    const pageCount = computed(() => Math.ceil(companiesCounter.value / itemsPerPage.value))

    onMounted(() => {
      setCurrentPageBreadcrumbs('Companies Listing', ['Companies'])
      const filter: CompaniesPayload = { filter: { limit: itemsPerPage.value, offset: selectedPage.value } }
      store.dispatch(MutationsActions.API_GET_MUTATE_COMPANIES, filter)
      store.dispatch(MutationsActions.API_GET_MUTATE_COMPANIES_COUNT)
    })

    const metrics = ref(computed(() => store.getters.getMetrics)) as unknown as Metrics

    const formatDate = (dateString) => {
      const date = dayjs(dateString)
      return date.format('MMM D, HH:mm')
    }

    const dialogVisible = ref(false)
    const deleteCompanyInput = ref('')
    const companyToDeleteLabel = ref('')
    const companyToDeleteUuid = ref('')
    const deleteLoading = ref(false)

    const showDeleteCompanyModal = (command) => {
      dialogVisible.value = true
      const filter: MetricsPayload = { metrics: ['num_sessions', 'num_accounts', 'num_unique_users'], filter: { companies: [command.uuid] } }
      store.dispatch(MutationsActions.API_GET_MUTATE_METRICS_BY_FILTER, filter)
      companyToDeleteLabel.value = command.label
      companyToDeleteUuid.value = command.uuid
      // clear input
      deleteCompanyInput.value = ''
    }

    const onDeleteCompany = () => {
      deleteLoading.value = true
      store.dispatch(Actions.API_DELETE_COMPANY, companyToDeleteUuid.value).then((response) => {
        deleteLoading.value = false
        if (response.status === 200) {
          Swal.fire({
            html: 'You have successfully deleted the company <b>' + companyToDeleteLabel.value + '</b>',
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: 'Ok, got it!',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-primary'
            }
          }).then(function (isOkPressed) {
            if (isOkPressed) {
              // remove from datatable as well
              for (let i = 0; i < tableData.value.length; i++) {
                if (tableData.value[i].companyUuid === companyToDeleteUuid.value) {
                  tableData.value.splice(i, 1)
                }
              }
              dialogVisible.value = false
            }
          })
        }
      })
    }

    const onItemsPerPageChange = (val) => {
      search.value = ''
      itemsPerPage.value = val
      const filter: CompaniesPayload = { filter: { limit: itemsPerPage.value, offset: (itemsPerPage.value * (selectedPage.value - 1)) } }
      store.dispatch(MutationsActions.API_GET_MUTATE_COMPANIES, filter)
    }

    const onCurrentPageChanged = (page) => {
      selectedPage.value = page
      const payload: CompaniesPayload = { filter: { limit: itemsPerPage.value, offset: (itemsPerPage.value * (selectedPage.value - 1)) } }

      if (search.value !== '') {
        payload.filter.label = search.value
      }
      store.dispatch(MutationsActions.API_GET_MUTATE_COMPANIES, payload)
    }

    const handleActions = (command) => {
      switch (command.action) {
        case 'overview':
          router.push('/companies/overview/' + command.uuid)
          break
        case 'edit':
          router.push('/companies/edit/' + command.uuid)
          break
        case 'delete':
          showDeleteCompanyModal(command)
          break
        default:
          break
      }
    }

    const search = ref<string>('')

    let searchTimer

    const searchItems = () => {
      clearTimeout(searchTimer)
      store.commit(Mutations.SET_FETCH_IN_PROGRESS, true)
      const payload: CompaniesPayload = { filter: { limit: itemsPerPage.value, offset: (itemsPerPage.value * (selectedPage.value - 1)) } }

      if (search.value !== '') {
        // filter by label
        payload.filter.label = search.value
      }

      searchTimer = setTimeout(() => {
        store.dispatch(MutationsActions.API_GET_MUTATE_COMPANIES, payload)
        const countPayload:CompaniesPayload = { filter: { limit: itemsPerPage.value } }
        if (search.value !== '') {
          countPayload.filter.label = search.value
        }
        store.dispatch(MutationsActions.API_GET_MUTATE_COMPANIES_COUNT, countPayload)
      }, 500)
    }

    return {
      tableData,
      tableHeader,
      showDeleteCompanyModal,
      search,
      searchItems,
      checkedCustomers,
      isGetCompaniesInProgress,
      onItemsPerPageChange,
      companiesCounter,
      onCurrentPageChanged,
      pageCount,
      handleActions,
      formatDate,
      dialogVisible,
      deleteCompanyInput,
      companyToDeleteLabel,
      metrics,
      deleteLoading,
      onDeleteCompany
    }
  }
})
